var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employees")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'associate-contract',
        query: {
          redirect: this.$route.fullPath
        }
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Files/File.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add new contract")) + " ")])])], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.persona",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [!item.persona.avatar_display ? _c('div', {
          staticClass: "symbol symbol-40 symbol-light-primary flex-shrink-0"
        }, [_c('span', {
          staticClass: "symbol-label font-size-h4 font-weight-bold"
        }, [_vm._v(_vm._s(item.persona.name[0]) + _vm._s(item.persona.surname[0]))])]) : _c('div', {
          staticClass: "symbol symbol-40 symbol-light-primary flex-shrink-0"
        }, [_c('img', {
          attrs: {
            "src": item.persona.avatar_display,
            "alt": "avatar"
          }
        })]), _c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "text-capitalize font-weight-bolder"
        }, [_vm._v(_vm._s(item.persona.name) + " " + _vm._s(item.persona.surname))]), _c('div', {
          staticClass: "text-muted font-size-xs"
        }, [_vm._v(_vm._s(item.persona.email))])])])];
      }
    }, {
      key: "cell.start_date",
      fn: function fn(_ref2) {
        var _item$employeecontrac;
        var item = _ref2.item;
        return [item !== null && item !== void 0 && (_item$employeecontrac = item.employeecontract_set[0]) !== null && _item$employeecontrac !== void 0 && _item$employeecontrac.start_date ? _c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(" " + _vm._s(_vm.DateService.format(item.employeecontract_set[0].start_date, _vm.dateFormat)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell.end_date",
      fn: function fn(_ref3) {
        var _item$employeecontrac2;
        var item = _ref3.item;
        return [item !== null && item !== void 0 && (_item$employeecontrac2 = item.employeecontract_set[0]) !== null && _item$employeecontrac2 !== void 0 && _item$employeecontrac2.end_date ? _c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(" " + _vm._s(_vm.DateService.format(item === null || item === void 0 ? void 0 : item.employeecontract_set.end_date, _vm.dateFormat)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell.CCNL",
      fn: function fn(_ref4) {
        var _item$employeecontrac3, _item$employeecontrac4, _item$employeecontrac5, _item$employeecontrac6, _item$employeecontrac7, _item$employeecontrac8;
        var item = _ref4.item;
        return [item !== null && item !== void 0 && (_item$employeecontrac3 = item.employeecontract_set[0]) !== null && _item$employeecontrac3 !== void 0 && (_item$employeecontrac4 = _item$employeecontrac3.national_contract) !== null && _item$employeecontrac4 !== void 0 && _item$employeecontrac4.name ? _c('div', {
          staticClass: "d-flex align-items-center"
        }, [item !== null && item !== void 0 && (_item$employeecontrac5 = item.employeecontract_set[0]) !== null && _item$employeecontrac5 !== void 0 && (_item$employeecontrac6 = _item$employeecontrac5.national_contract) !== null && _item$employeecontrac6 !== void 0 && _item$employeecontrac6.country ? _c('div', [_c('country-flag', {
          staticClass: "mr-4",
          attrs: {
            "country-iso": item === null || item === void 0 ? void 0 : (_item$employeecontrac7 = item.employeecontract_set[0]) === null || _item$employeecontrac7 === void 0 ? void 0 : _item$employeecontrac7.national_contract.country
          }
        })], 1) : _vm._e(), _c('span', {
          staticClass: "national-contract"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$employeecontrac8 = item.employeecontract_set[0]) === null || _item$employeecontrac8 === void 0 ? void 0 : _item$employeecontrac8.national_contract.name))])]) : _vm._e()];
      }
    }, {
      key: "cell.level",
      fn: function fn(_ref5) {
        var _item$employeecontrac9, _item$employeecontrac10;
        var item = _ref5.item;
        return [item !== null && item !== void 0 && (_item$employeecontrac9 = item.employeecontract_set[0]) !== null && _item$employeecontrac9 !== void 0 && _item$employeecontrac9.national_contract_level ? _c('div', [_c('span', {
          staticClass: "national-contract"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$employeecontrac10 = item.employeecontract_set[0]) === null || _item$employeecontrac10 === void 0 ? void 0 : _item$employeecontrac10.national_contract_level.level_name))])]) : _vm._e()];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.$t('View Details'),
            "disabled": !(item !== null && item !== void 0 && item.persona.id)
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)])];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }