<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Employees") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link :to="{ name: 'associate-contract', query: { redirect: this.$route.fullPath } }"
          class="btn btn-light-primary font-weight-bolder">
          <div class="d-flex">
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Files/File.svg" />
            </span>
            {{ $t("Add new contract") }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="card-body detail">
      <datatable ref="datatableRef" :table-props="tableProps" :total="total" :options.sync="tableOptions"
        :per-page-options="perPageOptions">
        <!-- CELLS -->
        <template #[`cell.persona`]="{ item }">
          <div class="d-flex align-items-center">
            <div v-if="!item.persona.avatar_display" class="symbol symbol-40 symbol-light-primary flex-shrink-0">
              <span class="symbol-label font-size-h4 font-weight-bold">{{ item.persona.name[0] }}{{
                item.persona.surname[0]
              }}</span>
            </div>
            <div v-else class="symbol symbol-40 symbol-light-primary flex-shrink-0">
              <img :src="item.persona.avatar_display" alt="avatar" />
            </div>
            <div class="ml-3">
              <div class="text-capitalize font-weight-bolder">{{ item.persona.name }} {{ item.persona.surname }}</div>
              <div class="text-muted font-size-xs">{{ item.persona.email }}</div>
            </div>
          </div>
        </template>

        <template #[`cell.start_date`]="{ item }">
          <span v-if="item?.employeecontract_set[0]?.start_date" class="font-weight-bolder text-muted">
            {{ DateService.format(item.employeecontract_set[0].start_date, dateFormat) }}
          </span>
        </template>

        <template #[`cell.end_date`]="{ item }">
          <span v-if="item?.employeecontract_set[0]?.end_date" class="font-weight-bolder text-muted">
            {{ DateService.format(item?.employeecontract_set.end_date, dateFormat) }}
          </span>
        </template>

        <template #[`cell.CCNL`]="{ item }">
          <div v-if="item?.employeecontract_set[0]?.national_contract?.name" class="d-flex align-items-center">
            <div v-if="item?.employeecontract_set[0]?.national_contract?.country">
              <country-flag class="mr-4"
                :country-iso="item?.employeecontract_set[0]?.national_contract.country"></country-flag>
            </div>
            <span class="national-contract">{{ item?.employeecontract_set[0]?.national_contract.name }}</span>
          </div>
        </template>

        <template #[`cell.level`]="{ item }">
          <div v-if="item?.employeecontract_set[0]?.national_contract_level">
            <span class="national-contract">{{ item?.employeecontract_set[0]?.national_contract_level.level_name }}</span>
          </div>
        </template>

        <template #[`cell.actions`]="{ item }">
          <b-button v-b-tooltip.hover :title="$t('View Details')" :disabled="!item?.persona.id"
            class="btn btn-icon btn-light btn-hover-primary btn-sm" @click="edit(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/Design/Edit.svg" />
            </span>
          </b-button>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons";
import EmployeeService from "@/core/services/employee/employee.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import CountryFlag from "@/view/components/CountryFlag.vue";
import Swal from "sweetalert2";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import {backendErrorSwal} from "@/core/helpers/swal";

export default {
  components: {
    Datatable,
    CountryFlag,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      DateService,
      fields: [
        { key: "persona", label: this.$t('employee'), sortable: true },
        { key: "start_date", label: this.$t('start date'), sortable: true },
        { key: "end_date", label: this.$t('end date'), sortable: true },
        { key: "CCNL", label: this.$t('CCNL'), sortable: true, tdClass: "national-contract" },
        { key: "level", label: this.$t('level'), sortable: true, tdClass: "national-contract" },
        { key: "actions", label: this.$t('actions'), class: "align-end col-actions" },
      ],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
    };
  },
  computed: {
    ...mapGetters("user", ["dateFormat"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        "no-sort-reset": true,
        responsive: true,
        "tbody-tr-class": this.trClass,
      };
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Employees") },
    ]);
  },

  methods: {
    refreshEmployeeTable() {
      this.$bvModal.hide("newEmployee");
      this.refresh();
    },
    itemProvider(ctx, callback) {
      EmployeeService.getAll({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
        companyId: this.legalPerson.id,
        fields: 'persona.id,persona.avatar_display,persona.name,persona.surname,persona.email,employeecontract_set'
      }).then(res => {
        this.total = res.count;
        callback(res.results);
      }).catch((err) => {
        backendErrorSwal(err, "Failed to load items");
        callback([]);
      });

      return null;
    },

    refresh() {
      this.$refs.datatableRef.refresh();
    },

    edit(item) {
      this.$router.push({
        name: "detail-physical-person",
        params: { userID: item.persona.id },
      });
    },

    remove(item) {
      Swal.fire({
        title: "DELETE",
        text: `Are you sure you want to delete ${item.name} ${item.surname}?`,
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: "Yes delete",
        showCancelButton: true,
        cancelButtonText: "No, do NOT delete",
        cancelButtonClass: "btn",
      }).then(async res => {
        if (res.isConfirmed) {
          try {
            await EmployeeService.delete(item.id);
            Swal.fire("Success", "Physical person deleted successfully", "success");
            this.refresh();
          } catch (err) {
            console.error(err);
            backendErrorSwal(err, err?.response?.data?.detail);
          }
        }
      });
    },
  },
};
</script>


<style scoped>
i.arrow {
  font-size: 0.7rem;
}

.per-page-select {
  max-width: 5rem;
}

.max-width-column {
  max-width: 200px;
}

::v-deep .national-contract {
  max-width: 32ch;
}

::v-deep .country-flag {
  min-width: 16px;
  max-width: 16px;
}

.text-capitalize {
  text-transform: capitalize;
}
</style>
